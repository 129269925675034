import { Link } from 'gatsby';
import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

const Inner = () => {
  const [hover, setHover] = useState(false);

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <motion.span
        initial={{ x: 0 }}
        animate={{ x: hover ? 10 : 0 }}
        transition={{ duration: 0.2 }}
        className="open"
      >
        (
      </motion.span>
      <motion.span
        initial={{ visibility: 'visible' }}
        animate={{ visibility: hover ? 'hidden' : 'visible' }}
        className="x"
      >
        X
      </motion.span>
      <motion.span
        initial={{ x: 0 }}
        animate={{ x: hover ? -10 : 0 }}
        transition={{ duration: 0.2 }}
        className="close"
      >
        )
      </motion.span>
    </div>
  );
};

const Close = ({ onClick, style }) => (
  <StyledClose className="hl close" onClick={onClick} style={style}>
    {onClick ? (
      <Inner />
    ) : (
      <Link to="/">
        <Inner />
      </Link>
    )}
  </StyledClose>
);

const StyledClose = styled.div`
  position: fixed;
  right: var(--sp-1-3);
  top: var(--sp-1-3);
  color: var(--w);
  z-index: 10;
  cursor: pointer;

  .open,
  .close {
    display: inline-block;
  }
`;

Close.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
};

export default Close;
