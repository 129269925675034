import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { Global, css } from '@emotion/react';
import SEO from '../components/organisms/seo';
import { media } from '../utils/media-queries';
import Close from '../components/atoms/close';

const ProjectsPage = ({ data }) => {
  const { year, projects, bgColor } = data.projectsPage.nodes[0];

  return (
    <>
      <Global
        styles={css`
          .main-wrapper {
            background: ${bgColor.hex};
          }
        `}
      />
      <SEO siteTitle="Projekte" />
      <StyledProject>
        <h1 className="hl">Projekte</h1>
        <Close />
        <div className="text">
          <p className="name underl">Erik Jan Rippmann</p>
          <div className="hl-fix">
            <p>Projekte</p>
            <p>{year}</p>
          </div>
        </div>
        <div className="projects hl">
          <div className="wrapper">
            {projects.map((project) => (
              <Link
                to={`/projekte/${project.slug.current}`}
                className="hl-hover"
              >
                {project.title}
              </Link>
            ))}
          </div>
        </div>
      </StyledProject>
    </>
  );
};

const StyledProject = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  h1 {
    padding-top: var(--sp-1-3);
    padding-left: var(--sp-1-3);
    color: var(--w);
  }

  .text {
    order: 2;
    padding-left: var(--sp-1-3);
    padding-bottom: var(--sp-1-3);

    .name {
      display: inline-block;
      font-weight: bold;
      margin-bottom: var(--sp-1);
      margin-left: 3px;
    }

    .year {
    }
  }

  a {
    display: block;
  }

  .projects {
    order: 1;
    padding-left: var(--sp-1-3);
    padding-top: var(--sp-3);
    padding-bottom: var(--sp-5);
  }

  @media ${media.L} {
    display: block;
    overflow: hidden;
    height: 100vh;

    h1 {
      position: sticky;
      top: 0;
    }

    .text {
      position: absolute;
      bottom: var(--sp-1-3);
      padding-bottom: 0;
    }

    .projects {
      position: absolute;
      top: 100vh;
      bottom: 100px;
      transform: rotate(-90deg);
      transform-origin: top left;
      padding-top: 33vw;
      padding-bottom: 0;

      .wrapper {
        overflow: scroll;
        max-height: calc(var(--fs-hl) * 9);

        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
`;

export const query = graphql`
  query Projects {
    projectsPage: allSanityProjectsPage {
      nodes {
        year
        bgColor {
          hex
        }
        projects {
          slug {
            current
          }
          title
        }
      }
    }
  }
`;

ProjectsPage.propTypes = {
  data: PropTypes.object,
};

export default ProjectsPage;
